<template>

</template>

<script>
import { bindUserByInvitationCode } from '@/api/user';
import { getToSchoolUrl } from '@/api/system';
import { Toast } from 'vant';

export default {
  name: 'hrefToSchool',
  created() {
    /*
      let urlParams = {
        shopId: GET_SHOP_ID(), // 商铺id
        contentId: mission.contentId,
        taskId: mission.taskId, // 分享taskId
        userTaskId: mission.userTaskId, // 分享userTaskId
        state: encodeURIComponent('/hrefToSchool'),
        path: `missionShareDetail`,
      };
     */
    let { invitationCode, shopId, contentId, taskId, userTaskId, path } = this.$route.query;

    // bindUserByInvitationCode({ invitationCode }).then(res => {
    //   console.log('bind invitationCode:', invitationCode, 'res:', res);
    getToSchoolUrl().then(res => {
      let url = res.url;
      if (url) {
        if (path === 'missionShareDetail') {
          location.href = `${url}&path=${path}&contentId=${contentId}&taskId=${taskId}&userTaskId=${userTaskId}`;
          // window.open(`${url}path=${path}contentId=${contentId}&taskId=${taskId}&userTaskId=${userTaskId}`);
        } else
          window.open(url);
      } else
        Toast('地址错误');
    });
    // });
  }
};
</script>

<style scoped>

</style>
